/* eslint-disable no-irregular-whitespace */
import React from 'react'
import styled from 'styled-components'
import { graphql } from 'gatsby'

import { TemplateSpontaneousApplicationFormDataQuery } from '../../../gatsby-types'
import Breadcrumb from '../../components/common/breadcrumb'
import JobOfferForm from '../../components/forms/job-offer'
import Layout from '../../layouts/default'
import { linkGenerator, PrismicDocumentType } from '../../utils/link-generator'
import { getTitle } from '../../utils/prismic'

interface IProps {
  data: TemplateSpontaneousApplicationFormDataQuery
  path?: string
}

export default function JobApplication({ data: { prismicHome, prismicCareer }, path }: IProps) {
  const title = 'Candidature spontanée'

  return (
    <Layout path={path} title={getTitle('Candidature spontanée')}>
      <Container>
        <Breadcrumb
          links={[
            { label: prismicHome?.data?.name?.text || '', url: linkGenerator(PrismicDocumentType.HOME) },
            {
              label: prismicCareer?.data?.name?.text || '',
              url: linkGenerator(PrismicDocumentType.PAGE, prismicCareer?.uid),
            },
            {
              label: title.toUpperCase(),
              url: linkGenerator(PrismicDocumentType.JOB_OFFER_FORM, null, prismicCareer?.uid),
            },
          ]}
        />
        <h2>Formulaire de candidature</h2>
        <JobOfferForm />
      </Container>
    </Layout>
  )
}

export const query = graphql`
  query TemplateSpontaneousApplicationFormData {
    prismicHome {
      data {
        name {
          text
        }
      }
    }
    prismicCareer {
      uid
      data {
        name {
          text
        }
      }
    }
  }
`

const Container = styled.div`
  align-items: flex-start;
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
  width: 100%;

  & > h2 {
    color: ${({ theme }) => theme.palette.primary.main};
    font-family: 'Proxima Nova Extrabold', sans-serif;
    font-size: 52px;
    margin: 16px 0 24px 0;
    text-transform: uppercase;

    @media screen and (max-width: 480px) {
      font-size: 34px;
      margin: 24px 0;
    }
  }
`
